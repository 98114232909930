<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="accession_number"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.accession_number')}}
                            </template>
                            <b-form-input
                              id="accession_number"
                              v-model="search.accession_number"
                            ></b-form-input>
                          </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('germplasm.gid_no')"
                        label-for="gid_no"
                        >
                        <b-form-input
                            id="org_name"
                             v-model="search.gid_no"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('germplasm.conservation_details')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(crop_name)="data">
                                           <span class="capitalize">{{ data.item.crop_name }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { conservationDetailsList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                accession_number: '',
                gid_no: '',
                org_id: this.$store.state.dataFilters.orgId
            }
        }
    },
    computed: {
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.gpCropTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('germplasm.conservation_details') + ' ' + this.$t('globalTrans.entry') : this.$t('germplasm.conservation_details') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('seedsSeeds.organization'), class: 'text-left' },
                { label: this.$t('germplasm.accession_number'), class: 'text-left' },
                { label: this.$t('germplasm.gid_no'), class: 'text-left' },
                { label: this.$t('seedsConfig.cropType'), class: 'text-left' },
                { label: this.$t('germConfig.cropName'), class: 'text-left' },
                { label: this.$t('germConfig.conservation_type'), class: 'text-left' },
                { label: this.$t('germplasm.collector_number'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_name_bn' },
                { key: 'accession_number' },
                { key: 'gid_no' },
                { key: 'crop_type_name_bn' },
                { key: 'crop_name_bn' },
                { key: 'conservation_type_name_bn' },
                { key: 'collector_number' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'accession_number' },
                { key: 'gid_no' },
                { key: 'crop_type_name' },
                { key: 'crop_name' },
                { key: 'conservation_type_name' },
                { key: 'collector_number' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, conservationDetailsList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map(item => {
            const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(doc => doc.value === parseInt(item.crop_type_id))
            const cropTypeData = {}
            if(typeof cropTypeObj !== 'undefined') {
              cropTypeData.crop_type = cropTypeObj.text_en
              cropTypeData.crop_type_bn = cropTypeObj.text_bn
            } else {
              cropTypeData.crop_type = ''
              cropTypeData.crop_type_bn = ''
            }

            const OrgObj = this.$store.state.commonObj.organizationProfileList.find(listitem => listitem.value === item.org_id && listitem.status === 0)
            const orgData = {}
            if(typeof OrgObj !== 'undefined') {
              orgData.org_name = OrgObj.text_en
              orgData.org_name_bn = OrgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }

            return Object.assign({}, item, cropTypeData, orgData)
          })
          return listData
        }
    }
}
</script>
