<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Collector Number" vid="collector_number" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="collector_number"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.collector_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="collector_number"
                                v-model="formData.collector_number"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @change="getDataByAccessionNumber(formData.collector_number)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col>
                         <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-overlay class="mt-4" :show="loading">
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collection Date" vid="collection_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.collection_date')}}
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData2.collection_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collection No" vid="collection_no" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="collection_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.collection_no')}}
                              </template>
                              <b-form-input
                                  id="collection_no"
                                  v-model="formData2.collection_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collector Number" vid="collector_number" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="collector_number"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.collector_number')}}
                              </template>
                              <b-form-input
                                  id="collector_number"
                                  v-model="formData2.collector_number"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Expedation/Organization" vid="collection_no" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="expedition_organization"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.expedition_organization')}}
                              </template>
                              <b-form-input
                                  id="expedition_organization"
                                  v-model="formData2.expedition"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Image" vid="attachment" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="attachment"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.proto_no')}}
                              </template>
                              <b-form-file
                                  id="proto_no"
                                  v-on:change="onFileChange"
                                  v-model="formData2.attachment"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <a v-if="formData2.attachment" target = '_blank' :href="seedFertilizerServiceBaseUrl + 'download-attachment?file=app/public/collections/' + formData2.attachment" class="btn btn-sm btn-success mt-2" download>
                                <i class="fas fa-download m-0"></i> {{$t('globalTrans.view_download')}}
                              </a>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Map Sheet" vid="map_sheet" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="map_sheet"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.map_sheet')}}
                              </template>
                              <b-form-input
                                  id="map_sheet"
                                  v-model="formData2.map_sheet"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Type"  vid="crop_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.cropType')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData2.crop_type_id"
                                  :options="cropTypeList"
                                  id="crop_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name"  vid="crop_name_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_name_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.cropName')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData2.crop_name_id"
                                  :options="cropNameList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Scientific Name" vid="scientific_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="scientific_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.scientific_name')}}
                              </template>
                              <b-form-input
                                  id="scientific_name"
                                  v-model="formData2.scientific_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="English Name" vid="english_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="english_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germConfig.english_name')}}
                              </template>
                              <b-form-input
                                  id="english_name"
                                  v-model="formData2.english_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  readonly
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Local Name (En)" vid="local_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="local_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.local_name_en')}}
                              </template>
                              <b-form-input
                                  id="local_name"
                                  v-model="formData2.local_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Local Name (Bn)" vid="local_name_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="local_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.local_name_bn')}}
                              </template>
                              <b-form-input
                                  id="local_name_bn"
                                  v-model="formData2.local_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Latitude" vid="latitude" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="latitude"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.latitude')}}
                              </template>
                              <b-form-input
                                  id="latitude"
                                  v-model="formData2.latitude"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Longitude" vid="longitude" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="longitude"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.longitude')}}
                              </template>
                              <b-form-input
                                  id="longitude"
                                  v-model="formData2.longitude"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Altitude" vid="altitude" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="altitude"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.altitude')}}
                              </template>
                              <b-form-input
                                  id="altitude"
                                  v-model="formData2.altitude"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="area_type_id" vid="area_type_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="area_type_id "
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('org_pro.area_type')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData2.area_type_id"
                                  id="area_type_id"
                                  :options="getAreaTypeList"
                                  @change="getAreaTypeData(formData2.area_type_id)"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="ItemShow">
                          <ValidationProvider name="Division"  vid="division_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.division')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData2.division_id"
                                  :options="divisionList"
                                  id="division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="ItemShow">
                          <ValidationProvider name="District"  vid="district_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.district')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="formData2.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              >
                                <template v-slot:first>
                                  <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="PauroshobaItemShow || UnionItemShow">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="upazila_id"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.upazila')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData2.upazilla_id"
                                :options="upazilaList"
                                id="upazila_id"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="UnionItemShow">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="union_id"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.union')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData2.union_id"
                                :options="unionList"
                                id="union_id"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="CityCorpItemShow">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="city_corporation_id"
                          >
                            <template v-slot:label>
                              {{ $t('org_pro.city_corporation') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData2.city_corporation_id"
                                :options="cityCorporationList"
                                id="city_corporation_id"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="PauroshobaItemShow">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pauroshoba_id"
                          >
                            <template v-slot:label>
                              {{ $t('org_pro.pauroshoba') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData2.pauroshoba_id"
                                :options="pauroshobaList"
                                id="pauroshoba_id"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12" v-show="ItemShow">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="ward_id"
                          >
                            <template v-slot:label>
                              {{ $t('org_pro.ward') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData2.ward_id"
                                :options="wardList"
                                id="ward_id"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="collection_source"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.collection_source') }} {{ '(' + $t('germplasm.circle_one') + ')' }}
                            </template>
                            <b-form-checkbox-group
                                id="collection_source"
                                v-model="formData2.collection_source"
                                :options="collectionSource"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="tropography"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.tropography') }}
                            </template>
                            <b-form-checkbox-group
                                id="topography"
                                v-model="formData2.topography"
                                :options="tropography"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="site"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.site') }}
                            </template>
                            <b-form-checkbox-group
                                id="site"
                                v-model="formData2.site"
                                :options="site"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="soil_texture"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.soil_texture') }}
                            </template>
                            <b-form-checkbox-group
                                id="soil_texture"
                                v-model="formData2.soil_texture"
                                :options="soilTexture"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="drainage"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.drainage') }}
                            </template>
                            <b-form-checkbox-group
                                id="drainage"
                                v-model="formData2.drainage"
                                :options="drainage"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="sampling_method"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.sampling_method') }}
                            </template>
                            <b-form-checkbox-group
                                id="sampling_method"
                                v-model="formData2.sampling_method"
                                :options="samplingMethod"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="herbarium"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.herbarium') }}
                            </template>
                            <b-form-checkbox-group
                                id="herbarium"
                                v-model="formData2.herbarium"
                                :options="herbarium"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="state_of_sample"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.state_of_sample') }}
                            </template>
                            <b-form-checkbox-group
                                id="status_of_sample"
                                v-model="formData2.status_of_sample"
                                :options="stateOfSample"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Farmer Name" vid="farmer_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="collectors_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_name')}}
                              </template>
                              <b-form-input
                                  id="collectors_name"
                                  v-model="formData2.farmer_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Cultiver Name" vid="cultivar_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="cultivar_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.cultivar_name')}}
                              </template>
                              <b-form-input
                                  id="cultivar_name"
                                  v-model="formData2.cultivar_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Language" vid="language" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="language"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.language')}}
                              </template>
                              <b-form-input
                                  id="language"
                                  v-model="formData2.language"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Meaning Of Name" vid="meaning_of_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="meaning_of_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.meaning_of_name')}}
                              </template>
                              <b-form-input
                                  id="meaning_of_name"
                                  v-model="formData2.meaning_of_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="varietal_sample"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.varietal_sample') }}
                            </template>
                            <b-form-checkbox-group
                                id="varietal_sample"
                                v-model="formData2.varietal_sample"
                                :options="varietalSample"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="varietal_group"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.varietal_group') }}
                            </template>
                            <b-form-checkbox-group
                                id="varietal_group"
                                v-model="formData2.varietal_group"
                                :options="varietalGroup"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="origin"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.origin') }}
                            </template>
                            <b-form-checkbox-group
                                id="origin"
                                v-model="formData2.origin"
                                :options="origin"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="frequency"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.ferquency') }}
                            </template>
                            <b-form-checkbox-group
                                id="ferquency"
                                v-model="formData2.frequency"
                                :options="frequency"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="cultural_type"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.cultural_type') }}
                            </template>
                            <b-form-checkbox-group
                                id="cultural_type"
                                v-model="formData2.cultural_type"
                                :options="culturalType"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="cultural_practices"
                          >
                            <template v-slot:label>
                              {{ $t('germplasm.cultural_practices') }}
                            </template>
                            <b-form-checkbox-group
                                id="cultural_practices"
                                v-model="formData2.cultural_practices"
                                :options="culturalPractices"
                                name="flavour-1"
                                disabled
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collection Date" vid="showing_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.showing_date')}}
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData2.showing_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collection Date" vid="transplanting_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.transplanting_date')}}
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData2.transplanting_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Collection Date" vid="harvest_date" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="harvest_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.harvest_date')}}
                              </template>
                              <flat-pickr class="form-control"
                                          v-model="formData2.harvest_date"
                                          :placeholder="$t('globalTrans.select_date')"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          disabled
                              ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Maturity" vid="maturity" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="maturity"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.maturity')}}
                              </template>
                              <b-form-input
                                  id="maturity"
                                  v-model="formData2.maturity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.usage')}}
                              </template>
                              <b-form-input
                                  id="usage"
                                  v-model="formData2.usage"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title" style="text-align: center">{{$t('germplasm.details_about')}}</h4>
                        </template>
                      </iq-card>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="farmer_brief_characterization" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_brief_characterization')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.farmer_brief_characterization"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_brief_discription')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.farmer_brief_description"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_matching')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.farmer_matching_cultivars_micro_env"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_matching_management')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.farmer_matching_cultivars_mng_practices"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.farmer_matching_practice')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.farmer_matching_mng_practices_env"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.special_trait')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.special_traits"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.area_distribution')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.area_distribution_importance"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.seed_exchange')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.seed_exchange"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                          <ValidationProvider name="Usage" vid="usage" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="usage"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.gender_issue')}}
                              </template>
                              <b-form-textarea
                                  id="textarea"
                                  size="sm"
                                  v-model="formData2.gender_issue"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="2"
                                  disabled
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-overlay>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Conservation Type"  vid="conservation_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="conservation_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.conservation_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.conservation_type_id"
                                :options="gpConservationTypeList"
                                id="conservation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Accession Number" vid="accession_number" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="accession_number"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.accession_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="accession_number"
                                v-model="formData.accession_number"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="GID NO." vid="gid_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="gid_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.gid_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="gid_no"
                                v-model="formData.gid_no"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { conservationDetailsStore, conservationDetailsUpdate, getDataByAccessionNumber } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    // this.formData.org_id = this.$store.state.dataFilters.orgId
      if (this.id) {
        this.formData = this.getFormData()
        this.getDataByAccessionNumber(this.formData.collector_number)
      }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        collector_number: '',
        accession_number: '',
        conservation_type_id: 0,
        org_id: this.$store.state.dataFilters.orgId
      },
      formData2: {
        id: '',
        collection_date: '',
        collection_source_id: '0',
        collector_number: '',
        accession_number: '',
        local_name: '',
        local_name_bn: '',
        cultivar_name: '',
        cultivar_name_bn: '',
        map_sheet: '',
        area_type_id: '0',
        district_id: '0',
        division_id: '0',
        union_id: '0',
        upazila_id: '0',
        city_corporation_id: '0',
        pauroshoba_id: '0',
        ward_id: '0',
        longitude: '',
        latitude: '',
        altitude: '',
        farmer_name: '',
        collectors_name: '',
        crop_name_id: '0',
        scientific_name: '',
        english_name: '',
        collection_no: '',
        language: '',
        meaning_of_name: '',
        remarks: '',
        remarks_bn: '',
        status: 1,
        type_name: '0',
        crop_type_id: '0',
        address: '',
        address_bn: '',
        collector_name: '',
        collector_name_bn: '',
        attachment: '',
        collection_source: [],
        topography: [],
        site: [],
        soil_texture: [],
        drainage: [],
        sampling_method: [],
        herbarium: [],
        status_of_sample: [],
        varietal_sample: [],
        varietal_group: [],
        origin: [],
        frequency: [],
        cultural_type: [],
        cultural_practices: [],
        maturity: '',
        usage: '',
        showing_date: '',
        transplanting_date: '',
        harvest_date: '',
        farmer_brief_characterization: '',
        farmer_brief_description: '',
        farmer_matching_cultivars_micro_env: '',
        farmer_matching_cultivars_mng_practices: '',
        farmer_matching_mng_practices_env: '',
        special_traits: '',
        area_distribution_importance: '',
        seed_exchange: '',
        gender_issue: ''
      },
      ItemShow: false,
      CityCorpItemShow: false,
      PauroshobaItemShow: false,
      UnionItemShow: false,
      cropNameList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      wardList: [],
      attachment: '',
      loading: false,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl
    }
  },
  computed: {
    getAreaTypeList: function () {
      const objectData = this.$store.state.commonObj.wardTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    gpConservationTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpConservationTypeList.filter(item => item.status === 1)
    },
    collectionSource: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Farmland Commercial Market' : 'ফার্মল্যান্ড বাণিজ্যিক বাজার' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Threshing Floor Field Border' : 'মাটির তল মাঠ সীমানা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Firm Store Institute' : 'ফার্ম স্টোর ইনস্টিটিউট' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Village Market Wild (Specify e.g.ditch)' : 'ভিলেজ মার্কেট ওয়াইল্ড (উদাহরণস্বরূপ ডিচ নির্দিষ্ট করুন)' }
      ]
      return list
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    tropography: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Swamp' : 'জলাভূমি' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Flood Plain' : 'বন্যার সমতল' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Plain' : 'সরল' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Undulating' : 'আনডুলেটিং' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Hilly' : 'হিলি' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Mountainous' : 'পাহাড়ী' }
      ]
      return list
    },
    site: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Level' : 'স্তর' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Slope' : 'স্লোপে' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Summit' : 'সামিট' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Depression' : 'বিষণ্ণতা' }
      ]
      return list
    },
    soilTexture: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Sandy' : 'বেলে' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Loamy' : 'লোমি' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Clayey' : 'ক্লে' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Undulating' : 'আনডুলেটিং' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Silly' : 'বোকা' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Highly Organic' : 'উচ্চতর জৈব' }
      ]
      return list
    },
    drainage: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Poor' : 'দরিদ্র' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Moderate' : 'মাঝারি' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Good' : 'ভাল' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Excessive' : 'অতিরিক্ত' }
      ]
      return list
    },
    samplingMethod: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Random' : 'এলোমেলো' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Non-Random(Specify)' : 'নন-এলোমেলো (নির্দিষ্ট করুন)' }
      ]
      return list
    },
    herbarium: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'yes' : 'হ্যাঁ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'No' : 'না' }
      ]
      return list
    },
    stateOfSample: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Wild' : 'বন্য' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Weedy' : 'আগাছা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Local Cutiver' : 'স্থানীয় কটিভার' }
      ]
      return list
    },
    varietalSample: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Single Variety' : 'একক বিভিন্নতা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Variety Mixer' : 'ভ্যারাইটি মিক্সার' }
      ]
      return list
    },
    varietalGroup: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Indica' : 'ইন্ডিকা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Japonica' : 'জাপোনিকা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Javanica' : 'জাভানিকা' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Hybrid-Intermediate' : 'সংকর-মধ্যবর্তী' }
      ]
      return list
    },
    origin: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Local' : 'স্থানীয়' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Exotic (Specify)' : 'বহিরাগত (নির্দিষ্ট করুন)' }
      ]
      return list
    },
    frequency: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Abundant' : 'প্রচুর' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Frequent' : 'ঘন ঘন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Occassional' : 'আকর্ষনীয়' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Rare' : 'বিরল' }
      ]
      return list
    },
    culturalType: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Irrigated' : 'সেচ দেওয়া' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Rainfed Lowlnd' : 'রেইনফিড লোল্যান্ড' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Apianc' : 'অ্যাপিয়ানক' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Deep Water' : 'গভীর পানি' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Tidal Wet Land' : 'জলোচ্ছ্বাসের জলাভূমি' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Swamp' : 'জলাভূমি' }
      ]
      return list
    },
    culturalPractices: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Shifting' : 'স্থানান্তরিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Terraced' : 'ছাঁটাই' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Direct Seeding' : 'সরাসরি বপন' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Double Transplanting' : 'ডাবল ট্রান্সপ্লান্টিং' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Mixed Stand' : 'মিশ্র স্ট্যান্ড' }
      ]
      return list
    }
  },
  watch: {
    // 'formData.collector_number': function (newVal, oldVal) {
    //   this.getDataByAccessionNumber(newVal)
    // }
    'formData2.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData2.district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'formData2.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'formData2.city_corporation_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'formData2.pauroshoba_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'formData2.union_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByUnion(newVal)
    },
    'formData2.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'formData2.crop_name_id': function (newVal, oldVal) {
      const cropNameObj = this.cropNameList.find(item => item.value === newVal)
      if (typeof cropNameObj !== 'undefined') {
        if (this.currentLocale === 'bn') {
          this.formData2.scientific_name = cropNameObj.scientific_name_bn
          this.formData2.english_name = cropNameObj.english_name_bn
        } else {
          this.formData2.scientific_name = cropNameObj.scientific_name
          this.formData2.english_name = cropNameObj.english_name
        }
      } else {
        this.formData2.scientific_name = ''
        this.formData2.english_name = ''
      }
    }
  },
  methods: {
    onFileChange (e) {
      this.formData2.attachment = e.target.files[0]
    },
    getAreaTypeData (typeId) {
      this.districtList = []
      this.upazilaList = []
      this.unionList = []
      this.cityCorporationList = []
      this.pauroshobaList = []
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
        this.formData2.upazilla_id = 0
        this.formData2.union_id = 0
        this.formData2.pauroshoba_id = 0
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
        this.formData2.city_corporation_id = 0
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
        this.formData2.pauroshoba_id = 0
        this.formData2.city_corporation_id = 0
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.loading = true
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${conservationDetailsUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, conservationDetailsStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors.collector_number) {
          this.$toast.error({
            title: 'Error',
            message: 'The collector number has already been taken.',
            color: '#D6E09B'
          })
        }
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (cropTypeId) {
        return cropNameList.filter(union => union.crop_type_id === cropTypeId)
      }
      return cropNameList
    },
    getDataByAccessionNumber (assNum = 0) {
      if (assNum) {
        this.loading = true
        RestApi.getData(seedFertilizerServiceBaseUrl, getDataByAccessionNumber, { collector_number: assNum }).then(response => {
            this.loading = false
            if (response.success) {
                if (this.$i18n.locale === 'bn') {
                    Object.assign(this.formData2, response.data)
                  this.getAreaTypeData(this.formData2.area_type_id)
                } else {
                    Object.assign(this.formData2, response.data)
                  this.getAreaTypeData(this.formData2.area_type_id)
                    if (!this.id) {
                        Object.assign(this.formData, { conservation_type_id: 0 })
                    }
                }
            } else {
                this.formData = {
                    characterization_type_name: '',
                    accession_number: this.formData.accession_number,
                    local_name: '',
                    cultivar_name: '',
                    characterization_details: '',
                    characterization_description: '',
                    crop_type_name: '',
                    crop_name: '',
                    scientific_name: '',
                    english_name: '',
                    conservation_type_id: 0
                }
            }
        })
      }
    },
    getDistrictList (divId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divId) {
        return districtList.filter(district => district.division_id === divId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
      if (upazillaId) {
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      }
    },
    getWardListByCityCorportaion (cityCorpId) {
      const objectData = this.$store.state.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByPauroshoba (pauroshobaId) {
      const objectData = this.$store.state.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByUnion (unionId) {
      const objectData = this.$store.state.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    }
  }
}
</script>
